<template>
  <div class="duration-wrapper padding-top-layout">
    <div class="padding-left-40">
      <h1>{{ $t('setting_language.title_language_settings')}}</h1>
    </div>
    <v-form ref="form">
      <div class="duration-form" ref="form">
        <div class="duration-form__year">
          <p>{{ $t('setting_language.label_language')}}</p>
          <div class="duration-selection__item">
            <v-select
              class="select-item"
              :menu-props="{ contentClass: 'selecting select-durations', maxHeight: 240 }"
              solo
              dense
              :placeholder="textPlaceholder"
              :items="languages"
              item-value="id"
              item-text="name"
              v-model="language"
            />
          </div>
        </div>
        <input class="submit-btn" type="button" :value="buttonChangeLanguage" @click="validateForm" />
      </div>
    </v-form>
    <question-popup
      :dialog="questionDialog"
      :message="popupMessage"
      @submit="onSubmit"
      :isShowCancelBtn="isShowCancelBtn"
      @close="handleClose"
      :confirm="true"
      :confirmText="confirmText"
      :borderFocusNone="true"
      :closeText="closeText"
    />
    <error-popup
      :dialog="errorDialog"
      :message="popupMessage"
      @submit="handleClose"
      :isShowCancelBtn="isShowCancelBtn"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import QuestionPopup from '@/components/dialogs/question-popup.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { getLanguageApi, updateLanguageApi } from '@/api/language';
import { getLanguageSupplierApi, updateLanguageSupplierApi } from '@/api/supplier';
export default {
  name: 'SettingLanguage',
  components: { QuestionPopup, ErrorPopup },
  data() {
    return {
      breadcrumb: [
        {
          text: this.$t('setting_language.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('setting_language.hyperlink_setting'),
          disabled: false,
          href: ROUTES.SETTING,
        },
        {
          text: this.$t('setting_language.label_language_settings'),
          disabled: false,
          href: ROUTES.SETTING_LANGUAGE,
        },
      ],
      confirmText: this.$t('setting_language.button_set'),
      questionDialog: false,
      errorDialog: false,
      defaultMessage: this.$t('setting_language.description_message_confirm'),
      popupMessage: '',
      valid: true,
      isShowCancelBtn: true,
      isValidated: false,
      languages: [],
      language: null,
    };
  },

  computed: {
    ...mapState('userData', ['isCfpSupplier']),
    textPlaceholder() {
      return this.$t('setting_language.placeholder_unselect')
    },
    buttonChangeLanguage() {
      return this.$t('setting_language.button_change')
    },
    closeText() {
      return this.$t('popup.button_cancel')
    },
    isProduct() {
      return this.isCfpSupplier;
    },
  },
  watch: {
    isProduct: {
      handler() {
        this.checkBreadCrumbSupplier();
      },
      deep: true,
    }
  },
  async mounted() {
    this.checkBreadCrumbSupplier();
    this.language = this.$store.state.userData.language?.id;
    await this.getLanguages();
  },

  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('userData', ['updateLanguage']),

    handleClose() {
      this.errorDialog = false;
      this.questionDialog = false;
    },
    async onSubmit() {
      if (this.isValidated) {
        this.questionDialog = false;
        let newData = {
          language_id: this.language,

        };
        try {
          if(this.$route.query?.primary_uuid) {
            newData.primary_uuid = this.$route.query?.primary_uuid
            await updateLanguageSupplierApi(newData);
          } else {
            await updateLanguageApi(newData);
          }

          const infoLanguage = this.languages.filter((item) => item.id === this.language)[0]
          this.updateLanguage(infoLanguage);
          this.$i18n.locale = infoLanguage.code;
          location.reload();
          this.questionDialog = false;
        } catch (error) {
          console.warn(error);
          this.questionDialog = false;
        }
      }
    },
    validateForm() {
      this.questionDialog = true;
      this.isValidated = true;
      this.popupMessage = this.defaultMessage;
    },
    async getLanguages() {
      try {
        let languages = {}
        if(this.$route.query?.primary_uuid) {
          languages = await getLanguageSupplierApi();
        } else {
          languages = await getLanguageApi();
        }
        this.languages = languages.data;
        if(this.language === undefined) {
          this.language = this.languages[0].id
        }
      } catch (error) {

      }
    },
    checkBreadCrumbSupplier() {
      if (this.$route.path.includes(ROUTES.SUPPLIER)) {
        this.breadcrumb = [
          {
            text: this.isProduct
              ? this.$t('supplier_products.title_emission_data_submissions_form')
              : this.$t('supplier.title_primary_submission_form'),
            disabled: false,
            href: ROUTES.SUPPLIER,
          },
          {
            text: this.$t('setting_language.title_language_settings'),
            disabled: true,
            href: ROUTES.SETTING_LANGUAGE_SUPPLIER,
          },
        ];
      }
      this.updateBreadCrumb(this.breadcrumb);
    },
  }
};
</script>
<style lang="scss">
.select-durations {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: $monoOffWhite;
  }
  &::-webkit-scrollbar-thumb {
    background: $bgMid;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid $monoOffWhite;
    border-right: 4px solid $monoOffWhite;
  }
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  max-height: 280px !important;
  margin-top: 45px !important;
  z-index: 99 !important;
  &.fullmode{
    margin-top : 34px !important;
    
  }
  .theme--light.v-list {
    background: $monoOffWhite;
    .v-list-item {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      min-height: 40px;
      .v-list-item__content {
        padding: 8px 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: $bgCusLight;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.duration-wrapper {
  h1 {
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid;
    margin: 40px 0px 48px;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-align: left;

    font-family: 'Source Han Sans';
    font-style: normal;
    color: $monoBlack;
    margin-bottom: 80px;
  }
  .duration-form {
    p {
      width: 335px;
      height: 24px;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      margin-bottom: 8px;
    }
    .duration-form__year {
      margin-bottom: 48px;
    }
    .duration-form-detail {
      .duration-selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        .duration-selection__top {
          width: 100%;
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          > div {
            width: 134.25px;
          }
        }
        .subtracted-divider {
          height: 28px;
          width: 15px;
          margin: 9px 0px;
          align-items: center;
          transform: rotate(90deg);
        }
        .duration-selection__bottom {
          width: 100%;
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          > div {
            width: 134.25px;
          }
        }
      }
    }
    .submit-btn {
      margin: 48px 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 2px 20px;
      background: $goldMid;
      width: 100%;
      height: 56px;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;
      color: $monoWhite;
      &:hover {
        /* Gold/Light */

        background: $goldLight;
        box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
          0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
      }
      &:focus-within {
        // border: 2px solid $blueMid;
        padding: 14px 0px 18px;
        color: $monoWhite;
      }
    }
    .duration-note {
      position: static;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: $monoDark;
      margin-top: 48px;
      li {
        &::marker {
          font-size: 9px !important;
        }
      }
    }
  }
}
@include desktop {
  .duration-wrapper {
    h1 {
      margin: 0 0 48px;
    }
    form {
      width: 600px;
      margin: 0 auto;
    }
    .duration-selection {
      height: 40px;
      width: 100%;
      margin: unset !important;
      display: flex;
      flex-direction: row !important;
      .duration-selection__top,
      .duration-selection__bottom {
        > div {
          width: 134.25px;
        }
      }
      .duration-selection__top {
        flex-direction: column;
      }
      .subtracted-divider {
        margin: 16px !important;
        flex-direction: column;
        transform: rotate(0) !important;
        align-items: center;
      }
      .duration-selection__bottom {
        flex-direction: column;
      }
    }
  }

  .padding-left-40 {
    padding-left: 40px;
  }

  .submit-btn:focus {
    background-color: $goldMid !important;
    opacity: 1;
  }
}
</style>
